/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Form, Accordion } from 'react-bootstrap';
import {
  setClickBorderRadius,
  setRevealBorderRadius,
  setClickBorderTop,
  setClickBorderRight,
  setClickBorderBottom,
  setClickBorderLeft,
  setClickBorderColor,
  setClickBorderType,
  setClickBgColor,
  setRevealBorderTop,
  setRevealBorderRight,
  setRevealBorderBottom,
  setRevealBorderLeft,
  setRevealBorderColor,
  setRevealBorderType,
  setRevealBgColor,
  setClickPaddingTop,
  setClickPaddingRight,
  setClickPaddingBottom,
  setClickPaddingLeft,
  setRevealPaddingBottom,
  setRevealPaddingLeft,
  setRevealPaddingRight,
  setRevealPaddingTop,
} from '../../../redux/form/form.actions';
import { ConnectedProps, connect } from 'react-redux';
import { FlipFormInterface, FormInterface } from '../../../redux/interface';
import ColorPickerComponent from '../../customizeComponentLayout/colorPicker';
import SpaceComponent from '../../customizeComponentLayout/spaceComponent';
import BorderCustomizeComponent from '../../customizeComponentLayout/borderCustomize';
import {
  customFrontPaddingleft,
  customFrontPaddingright,
  customFrontPaddingtop,
  customFrontPaddingbottom,
  customBackPaddingbottom,
  customBackPaddingleft,
  customBackPaddingright,
  customBackPaddingtop,
  backColor,
  setBgColor,
  setBackBorderColor,
  setFrontBorderColor,
  setBackBorderRadius,
  radius,
  setBackBorderStyle,
  setBorderStyle,
  setBackBorderSizeLeft,
  setBackBorderSizeRight,
  setFrontBorderSizeBottom,
  setFrontBorderSizeRight,
  setFrontBorderSizeTop,
  setBackBorderSizeBottom,
  setBackBorderSizeTop,
  setBorderSize,
} from '../../../redux/flipCard/form.action';
import {
  cardsBgColor,
  cardsBorderBottom,
  cardsBorderColor,
  cardsBorderLeft,
  cardsBorderRadius,
  cardsBorderRight,
  cardsBorderStyle,
  cardsBorderTop,
  cardsPaddingBottom,
  cardsPaddingLeft,
  cardsPaddingRight,
  cardsPaddingTop,
  mapStateToProps,
} from './customizeUtils';

//props to redux
const mapDispatchToProps = (dispatch: Function) => ({
  SetClickBorderRadius: (form: FormInterface) => dispatch(setClickBorderRadius(form)),
  SetRevealBorderRadius: (form: FormInterface) => dispatch(setRevealBorderRadius(form)),
  SetClickBorderTop: (form: FormInterface) => dispatch(setClickBorderTop(form)),
  SetClickBorderRight: (form: FormInterface) => dispatch(setClickBorderRight(form)),
  SetClickBorderBottom: (form: FormInterface) => dispatch(setClickBorderBottom(form)),
  SetClickBorderLeft: (form: FormInterface) => dispatch(setClickBorderLeft(form)),
  SetClickBorderColor: (form: FormInterface) => dispatch(setClickBorderColor(form)),
  SetClickBorderType: (form: FormInterface) => dispatch(setClickBorderType(form)),
  SetClickBgColor: (form: FormInterface) => dispatch(setClickBgColor(form)),
  SetRevealBorderTop: (form: FormInterface) => dispatch(setRevealBorderTop(form)),
  SetRevealBorderRight: (form: FormInterface) => dispatch(setRevealBorderRight(form)),
  SetRevealBorderBottom: (form: FormInterface) => dispatch(setRevealBorderBottom(form)),
  SetRevealBorderLeft: (form: FormInterface) => dispatch(setRevealBorderLeft(form)),
  SetRevealBorderColor: (form: FormInterface) => dispatch(setRevealBorderColor(form)),
  SetRevealBorderType: (form: FormInterface) => dispatch(setRevealBorderType(form)),
  SetRevealBgColor: (form: FormInterface) => dispatch(setRevealBgColor(form)),
  SetClickPaddingTop: (form: FormInterface) => dispatch(setClickPaddingTop(form)),
  SetClickPaddingRight: (form: FormInterface) => dispatch(setClickPaddingRight(form)),
  SetClickPaddingBottom: (form: FormInterface) => dispatch(setClickPaddingBottom(form)),
  SetClickPaddingLeft: (form: FormInterface) => dispatch(setClickPaddingLeft(form)),
  SetRevealPaddingTop: (form: FormInterface) => dispatch(setRevealPaddingTop(form)),
  SetRevealPaddingRight: (form: FormInterface) => dispatch(setRevealPaddingRight(form)),
  SetRevealPaddingBottom: (form: FormInterface) => dispatch(setRevealPaddingBottom(form)),
  SetRevealPaddingLeft: (form: FormInterface) => dispatch(setRevealPaddingLeft(form)),
  SetFrontPaddingLeft: (flipform: FlipFormInterface) => dispatch(customFrontPaddingleft(flipform)),
  SetFrontPaddingRight: (flipform: FlipFormInterface) =>
    dispatch(customFrontPaddingright(flipform)),
  SetFrontPaddingTop: (flipform: FlipFormInterface) => dispatch(customFrontPaddingtop(flipform)),
  SetFrontPaddingBottom: (flipform: FlipFormInterface) =>
    dispatch(customFrontPaddingbottom(flipform)),
  SetBackPaddingLeft: (flipform: FlipFormInterface) => dispatch(customBackPaddingleft(flipform)),
  SetBackPaddingRight: (flipform: FlipFormInterface) => dispatch(customBackPaddingright(flipform)),
  SetBackPaddingTop: (flipform: FlipFormInterface) => dispatch(customBackPaddingtop(flipform)),
  SetBackPaddingBottom: (flipform: FlipFormInterface) =>
    dispatch(customBackPaddingbottom(flipform)),
  SetBgColor: (flipform: FlipFormInterface) => dispatch(setBgColor(flipform)),
  SetBackColor: (flipform: FlipFormInterface) => dispatch(backColor(flipform)),
  SetBackBorderColor: (flipform: FlipFormInterface) => dispatch(setBackBorderColor(flipform)),
  SetFrontBorderColor: (flipform: FlipFormInterface) => dispatch(setFrontBorderColor(flipform)),
  SetRadius: (flipform: FlipFormInterface) => dispatch(radius(flipform)),
  SetBackBorderRadius: (flipform: FlipFormInterface) => dispatch(setBackBorderRadius(flipform)),
  SetBorderStyle: (flipform: FlipFormInterface) => dispatch(setBorderStyle(flipform)),
  SetBackBorderStyle: (flipform: FlipFormInterface) => dispatch(setBackBorderStyle(flipform)),
  SetBorderSize: (flipform: FlipFormInterface) => dispatch(setBorderSize(flipform)),
  SetFrontBorderSizeRight: (flipform: FlipFormInterface) =>
    dispatch(setFrontBorderSizeRight(flipform)),
  SetFrontBorderSizeTop: (flipform: FlipFormInterface) => dispatch(setFrontBorderSizeTop(flipform)),
  SetFrontBorderSizeBottom: (flipform: FlipFormInterface) =>
    dispatch(setFrontBorderSizeBottom(flipform)),
  SetBackBorderSizeLeft: (flipform: FlipFormInterface) => dispatch(setBackBorderSizeLeft(flipform)),
  SetBackBorderSizeRight: (flipform: FlipFormInterface) =>
    dispatch(setBackBorderSizeRight(flipform)),
  SetBackBorderSizeTop: (flipform: FlipFormInterface) => dispatch(setBackBorderSizeTop(flipform)),
  SetBackBorderSizeBottom: (flipform: FlipFormInterface) =>
    dispatch(setBackBorderSizeBottom(flipform)),
});

interface CustomizeSectionInterface {
  customizeType: string;
}
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & CustomizeSectionInterface;

const CustomizeSection = (props: Props) => {
  const {
    customizeType,
    SetRadius,
    SetBackBorderRadius,
    SetBackBorderColor,
    SetFrontBorderColor,
    SetBackBorderStyle,
    SetBorderStyle,
    SetBorderSize,
    SetFrontBorderSizeRight,
    SetFrontBorderSizeTop,
    SetFrontBorderSizeBottom,
    SetBackBorderSizeLeft,
    SetBackBorderSizeRight,
    SetBackBorderSizeTop,
    SetBackBorderSizeBottom,
    SetClickBorderRadius,
    SetRevealBorderRadius,
    SetClickBorderTop,
    SetClickBorderRight,
    SetClickBorderBottom,
    SetClickBorderLeft,
    SetClickBorderColor,
    SetClickBorderType,
    SetClickBgColor,
    SetRevealBorderTop,
    SetRevealBorderRight,
    SetRevealBorderBottom,
    SetRevealBorderLeft,
    SetRevealBorderColor,
    SetRevealBorderType,
    SetRevealBgColor,
    SetClickPaddingTop,
    SetClickPaddingRight,
    SetClickPaddingBottom,
    SetClickPaddingLeft,
    SetRevealPaddingTop,
    SetRevealPaddingRight,
    SetRevealPaddingBottom,
    SetRevealPaddingLeft,
    SetFrontPaddingLeft,
    SetFrontPaddingRight,
    SetFrontPaddingTop,
    SetFrontPaddingBottom,
    SetBackPaddingRight,
    SetBackPaddingLeft,
    SetBackPaddingTop,
    SetBackPaddingBottom,
    SetBgColor,
    SetBackColor,
  } = props;

  const handleBorderType = (value: { borderStyle: string }) => {
    const { borderStyle } = value;
    switch (customizeType) {
      case 'click':
        SetClickBorderType({
          clickBorderType: borderStyle,
        });
        break;
      case 'reveal':
        SetRevealBorderType({
          revealBorderType: borderStyle,
        });
        break;
      case 'frontcard':
        SetBorderStyle({
          borderStyle: borderStyle,
        });
        break;
      case 'backcard':
        SetBackBorderStyle({
          backBorderStyle: borderStyle,
        });
        break;
    }
  };

  const handleBorderRadius = (value: { borderRadius: string }) => {
    const { borderRadius } = value;
    switch (customizeType) {
      case 'click':
        SetClickBorderRadius({
          clickBorderRadius: borderRadius,
        });
        break;
      case 'reveal':
        SetRevealBorderRadius({
          revealBorderRadius: borderRadius,
        });
        break;
      case 'frontcard':
        SetRadius({
          radius: borderRadius,
        });
        break;
      case 'backcard':
        SetBackBorderRadius({
          backBorderRadius: borderRadius,
        });
        break;
    }
  };

  const setBorderColor = (color: { borderColor: string }) => {
    const { borderColor } = color;
    switch (customizeType) {
      case 'click':
        SetClickBorderColor({
          clickBorderColor: borderColor,
        });
        break;
      case 'reveal':
        SetRevealBorderColor({
          revealBorderColor: borderColor,
        });
        break;
      case 'frontcard':
        SetFrontBorderColor({
          borderColor: borderColor,
        });
        break;
      case 'backcard':
        SetBackBorderColor({
          backBorderColor: borderColor,
        });
        break;
    }
  };

  const setBgColor = (color: { bgcolor: string }) => {
    const { bgcolor } = color;
    switch (customizeType) {
      case 'click':
        SetClickBgColor({
          clickBgColor: bgcolor,
        });
        break;
      case 'reveal':
        SetRevealBgColor({
          revealBgColor: bgcolor,
        });
        break;
      case 'frontcard':
        SetBgColor({
          bgcolor: bgcolor,
        });
        break;
      case 'backcard':
        SetBackColor({
          backColor: bgcolor,
        });
        break;
    }
  };

  const handleCustomBorder = (value: {
    borderLeft?: string;
    borderRight?: string;
    borderTop?: string;
    borderBottom?: string;
  }) => {
    const { borderLeft, borderBottom, borderRight, borderTop } = value;
    switch (true) {
      case typeof borderLeft === 'string':
        return customizeType === 'click'
          ? SetClickBorderLeft({
              clickBorderLeft: borderLeft,
            })
          : customizeType === 'reveal'
          ? SetRevealBorderLeft({
              revealBorderLeft: borderLeft,
            })
          : customizeType === 'frontcard'
          ? SetBorderSize({
              borderSize: borderLeft,
            })
          : SetBackBorderSizeLeft({
              backBorderSizeLeft: borderLeft,
            });
      case typeof borderTop === 'string':
        return customizeType === 'click'
          ? SetClickBorderTop({
              clickBorderTop: borderTop,
            })
          : customizeType === 'reveal'
          ? SetRevealBorderTop({
              revealBorderTop: borderTop,
            })
          : customizeType === 'frontcard'
          ? SetFrontBorderSizeTop({
              frontBorderSizeTop: borderTop,
            })
          : SetBackBorderSizeTop({
              backBorderSizeTop: borderTop,
            });
      case typeof borderRight === 'string':
        return customizeType === 'click'
          ? SetClickBorderRight({
              clickBorderRight: borderRight,
            })
          : customizeType === 'reveal'
          ? SetRevealBorderRight({
              revealBorderRight: borderRight,
            })
          : customizeType === 'frontcard'
          ? SetFrontBorderSizeRight({
              frontBorderSizeRight: borderRight,
            })
          : SetBackBorderSizeRight({
              backBorderSizeRight: borderRight,
            });
      case typeof borderBottom === 'string':
        return customizeType === 'click'
          ? SetClickBorderBottom({
              clickBorderBottom: borderBottom,
            })
          : customizeType === 'reveal'
          ? SetRevealBorderBottom({
              revealBorderBottom: borderBottom,
            })
          : customizeType === 'frontcard'
          ? SetFrontBorderSizeBottom({
              frontBorderSizeBottom: borderBottom,
            })
          : SetBackBorderSizeBottom({
              backBorderSizeBottom: borderBottom,
            });
      default:
        return;
    }
  };

  const handleCustomPadding = (value: {
    customPaddingleft?: string;
    customPaddingright?: string;
    customPaddingtop?: string;
    customPaddingbottom?: string;
  }) => {
    const { customPaddingleft, customPaddingbottom, customPaddingright, customPaddingtop } = value;
    switch (true) {
      case typeof customPaddingleft === 'string':
        return customizeType === 'click'
          ? SetClickPaddingLeft({
              clickPaddingLeft: customPaddingleft,
            })
          : customizeType === 'reveal'
          ? SetRevealPaddingLeft({
              revealPaddingLeft: customPaddingleft,
            })
          : customizeType === 'frontcard'
          ? SetFrontPaddingLeft({
              frontLeft: customPaddingleft,
            })
          : SetBackPaddingLeft({
              backLeft: customPaddingleft,
            });
      case typeof customPaddingtop === 'string':
        return customizeType === 'click'
          ? SetClickPaddingTop({
              clickPaddingTop: customPaddingtop,
            })
          : customizeType === 'reveal'
          ? SetRevealPaddingTop({
              revealPaddingTop: customPaddingtop,
            })
          : customizeType === 'frontcard'
          ? SetFrontPaddingTop({
              frontTop: customPaddingtop,
            })
          : SetBackPaddingTop({
              backTop: customPaddingtop,
            });
      case typeof customPaddingright === 'string':
        return customizeType === 'click'
          ? SetClickPaddingRight({
              clickPaddingRight: customPaddingright,
            })
          : customizeType === 'reveal'
          ? SetRevealPaddingRight({
              revealPaddingRight: customPaddingright,
            })
          : customizeType === 'frontcard'
          ? SetFrontPaddingRight({
              frontRight: customPaddingright,
            })
          : SetBackPaddingRight({
              backRight: customPaddingright,
            });
      case typeof customPaddingbottom === 'string':
        return customizeType === 'click'
          ? SetClickPaddingBottom({
              clickPaddingBottom: customPaddingbottom,
            })
          : customizeType === 'reveal'
          ? SetRevealPaddingBottom({
              revealPaddingBottom: customPaddingbottom,
            })
          : customizeType === 'frontcard'
          ? SetFrontPaddingBottom({
              frontBottom: customPaddingbottom,
            })
          : SetBackPaddingBottom({
              backBottom: customPaddingbottom,
            });
      default:
        return;
    }
  };

  return (
    <Accordion.Body className='pb-0 px-2 pt-3'>
      <Form onSubmit={(e) => e.preventDefault()}>
        <Form.Group className='mb-3 spacing-container' data-testid='border-component'>
          <SpaceComponent
            componentType='Padding'
            left={cardsPaddingLeft(props)}
            top={cardsPaddingTop(props)}
            right={cardsPaddingRight(props)}
            bottom={cardsPaddingBottom(props)}
            SetLeft={handleCustomPadding}
            SetTop={handleCustomPadding}
            SetRight={handleCustomPadding}
            SetBottom={handleCustomPadding}
          />
          <BorderCustomizeComponent
            borderLeft={cardsBorderLeft(props)}
            borderTop={cardsBorderTop(props)}
            borderRight={cardsBorderRight(props)}
            borderBottom={cardsBorderBottom(props)}
            SetBorderLeft={handleCustomBorder}
            SetBorderRight={handleCustomBorder}
            SetBorderBottom={handleCustomBorder}
            SetBorderTop={handleCustomBorder}
            borderStyle={cardsBorderStyle(props)}
            SetBorderStyle={handleBorderType}
            borderRadius={cardsBorderRadius(props)}
            SetBorderRadius={handleBorderRadius}
            borderColor={cardsBorderColor(props)}
            SetBorderColor={setBorderColor}
          />
        </Form.Group>
        <ColorPickerComponent
          type={`${customizeType}_bgColor`}
          title='Background Color'
          SetColor={setBgColor}
          color={cardsBgColor(props)}
        />
      </Form>
    </Accordion.Body>
  );
};

//connecting form with redux
export default connector(CustomizeSection);
