import React, { useEffect, useRef } from 'react';
import { useEditor, EditorContent, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Gapcursor from '@tiptap/extension-gapcursor';
import Text from '@tiptap/extension-text';
import Document from '@tiptap/extension-document';
import { Superscript } from '@tiptap/extension-superscript';
import { Subscript } from '@tiptap/extension-subscript';
import { TextStyle } from '@tiptap/extension-text-style';
import { NodeSelection } from '@tiptap/pm/state';
import { createMigration, migrateContent, updateMigration } from './migrate';
import { LoreeInteractiveEditorFormatOptions } from './loreeInteractionEditorFormatOptions';
import { CustomizedAnchorElement } from './editorExtensions/anchorElement';
import {
  CustomizedClassAttribute,
  CustomizedStyleAttribute,
  CustomizedUnderLine,
  CustomizedTextAlign,
  CustomizedPlaceholder,
} from './editorExtensions/libraryExtensions';
import { BulletList } from './editorExtensions/bulletListExtension';
import { CustomizedCaption } from './editorExtensions/captionElementExtension';
import { CommandExtension } from './editorExtensions/commands';
import { HardBreak } from './editorExtensions/customHardBreak';
import CustomizedImage from './editorExtensions/customImage';
import { CustomizedTextTransform } from './editorExtensions/textTransformExtension';
import { CustomizedHeading } from './editorExtensions/heading';
import { CustomizedIframeElement } from './editorExtensions/iframeElement';
import { CustomizedParagraph } from './editorExtensions/paragraph';
import { CustomizedFontFamily } from './editorExtensions/fontFamily';
import { CustomizedFontSize } from './editorExtensions/fontSize';
import { LineHeight } from './editorExtensions/lineHeight';
import { ListItem } from './editorExtensions/listItemExtension';
import { OrderedList } from './editorExtensions/orderedListExtension';
import { Padding } from './editorExtensions/padding';
import { Table } from './editorExtensions/tableExtension';
import { CustomizedTableColGroup } from './editorExtensions/tableColGroup';
import { CustomizedTableHead } from './editorExtensions/tableHead';
import { CustomizedTableRow } from './editorExtensions/tablerow';
import { CustomizedTableCell } from './editorExtensions/tableCell';
import { CustomizedTableBody } from './editorExtensions/tableBody';
import { CustomizedTableColumn } from './editorExtensions/tableColumn';
import { CustomizedTableHeader } from './editorExtensions/tableHeader';
import { TextIndent } from './editorExtensions/textIndent';
import { Color } from './editorExtensions/color';
import { BackgroundColor } from './editorExtensions/backgroundColor';
import { CustomizedBlockQuote } from './editorExtensions/blockquoteExtension';
import { BorderColor } from './editorExtensions/borderColor';
import { CustomizedVideoElement } from './editorExtensions/videoExtension';
import { IconTag } from './editorExtensions/iconTagElement';
import { CONSTANTS } from './editorUtilityFunctions/constants';
import { LoreeInteractiveEditorType } from './editorUtilityFunctions/lintEditorType';
import './editorUtilityFunctions/loreeInteractiveEditorCommonStyles.scss';
import {
  EditorTopSection,
  LoreeInteractiveEditorWrapper,
} from './editorUtilityFunctions/styledContents';
import { hideLinkInfoPopup, showLinkDetails } from '../linkPopup/showLinkInfoPopup';

export function LoreeInteractiveEditor(props: LoreeInteractiveEditorType) {
  const { initialValue, editorConfig, updateEditorContent, indexValue } = props;

  const editorContent = migrateContent(initialValue) ?? CONSTANTS.LOREE_EDITOR_EMPTY_CONTENT;

  const popperID = `${CONSTANTS.EDITOR_POPPER_ID}-${indexValue}`;
  const editorRef: React.MutableRefObject<Editor | null> = useRef(null);
  const lintEditor: Editor | null = useEditor({
    extensions: [
      StarterKit,
      Text,
      HardBreak,
      CommandExtension,
      Document.extend({ marks: 'link' }),
      ListItem,
      BulletList,
      OrderedList,
      Superscript,
      Subscript,
      TextStyle,
      CustomizedPlaceholder(editorConfig),
      CustomizedAnchorElement,
      CustomizedIframeElement,
      CustomizedClassAttribute,
      CustomizedStyleAttribute,
      CustomizedTextTransform,
      CustomizedTextAlign,
      CustomizedHeading,
      CustomizedParagraph,
      CustomizedImage,
      CustomizedUnderLine,
      CustomizedFontFamily,
      CustomizedFontSize,
      CustomizedVideoElement,
      Color,
      BackgroundColor,
      BorderColor,
      LineHeight,
      CustomizedBlockQuote,
      Table,
      CustomizedCaption,
      CustomizedTableColGroup,
      CustomizedTableHead,
      CustomizedTableRow,
      CustomizedTableCell,
      CustomizedTableBody,
      CustomizedTableColumn,
      CustomizedTableHeader,
      IconTag,
      TextIndent,
      Padding,
      Gapcursor,
    ],
    autofocus: true,
    content: editorContent,
    onUpdate({ editor }) {
      hideLinkInfoPopup();
      updateMigration(editor, editorConfig);
      updateEditorContent(editor.getHTML());
    },
    onCreate({ editor }) {
      createMigration(editor, editorConfig);
    },
    editorProps: {
      handleDOMEvents: {
        drop: (_view, e) => {
          e.preventDefault();
        },
        dragstart: (_view, e) => {
          e.preventDefault();
        },
        click: (_view, e) => {
          const currentEditor = editorRef.current;
          if (!currentEditor) return;
          const selection = currentEditor.state.selection as NodeSelection;
          showLinkDetails(
            e.target as HTMLElement,
            currentEditor,
            selection?.node?.type.name ?? '',
            popperID,
          );
          if (!selection?.empty && !selection?.node?.type.name) hideLinkInfoPopup();
        },
      },
    },
  });
  editorRef.current = lintEditor;

  useEffect(() => {
    if (lintEditor) {
      const currentContent = lintEditor.getHTML();
      if (currentContent !== editorContent) {
        lintEditor.commands.setContent(editorContent);
      }
    }
  }, [editorContent, lintEditor]);

  return (
    <EditorTopSection>
      <LoreeInteractiveEditorFormatOptions editor={lintEditor} editorConfig={editorConfig} />
      <EditorContent className={CONSTANTS.LOREE_INTERACTIVE_TIPTAP_EDITOR} editor={lintEditor}>
        <LoreeInteractiveEditorWrapper>
          <div id={popperID} />
        </LoreeInteractiveEditorWrapper>
      </EditorContent>
    </EditorTopSection>
  );
}
