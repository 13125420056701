import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as GlobalIcon } from '../../../src/assets/icons/globalIcon.svg';
import { ReactComponent as PrivateIcon } from '../../../src/assets/icons/sharedIcon.svg';
import { ReactComponent as SharedIcon } from '../../../src/assets/icons/privateIcon.svg';
import { ReactComponent as CreateIcon } from '../../../src/assets/icons/createIcon.svg';
import { InteractiveTooltip } from '../utils/interactivetooltip';
interface IconData {
  className: string;
  IconComponent: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  pathname: string;
  tooltipInfo: string;
}

const icons: IconData[] = [
  {
    className: 'createIcon',
    IconComponent: CreateIcon,
    pathname: '/interactives',
    tooltipInfo: 'Create New',
  },
  {
    className: 'globalIcon',
    IconComponent: GlobalIcon,
    pathname: '/global',
    tooltipInfo: 'Global Interactive',
  },
  {
    className: 'privateIcon',
    IconComponent: PrivateIcon,
    pathname: '/Contents',
    tooltipInfo: 'Private Interactive',
  },
  {
    className: 'sharedIcon',
    IconComponent: SharedIcon,
    pathname: '/shared',
    tooltipInfo: 'Shared Interactive ',
  },
];

const IconsSidebar = () => {
  const history = useHistory();
  const location = useLocation();
  const navigateToPath = (pathname: string) => {
    const user = sessionStorage.getItem('user') || 'defaultUser';
    const orgId = sessionStorage.getItem('org_id') || 'defaultOrg';
    const userId = sessionStorage.getItem('user_id') || 'defaultUserId';
    const loreeVersion = sessionStorage.getItem('loreeVersion') || '1.0';

    let finalPath = pathname;

    if (pathname === '/interactives') {
      finalPath = `${pathname}/${user}/${orgId}/${loreeVersion}`;
    } else if (pathname === '/Contents') {
      finalPath = `${pathname}/${user}/${userId}`;
    } else if (pathname === '/global') {
      finalPath = `${pathname}/${orgId}`;
    }

    history.push(finalPath);
  };
  const renderIcon = ({ className, IconComponent, pathname, tooltipInfo }: IconData) => {
    const isActive = location.pathname.startsWith(pathname);
    return (
      <InteractiveTooltip info={tooltipInfo} direction='right' key={className}>
        <div
          className={`${className}Div ${isActive ? 'active' : ''}`}
          role='button'
          tabIndex={0}
          onClick={() => {
            navigateToPath(pathname);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              navigateToPath(pathname);
            }
          }}
        >
          <div className={className}>
            <IconComponent width={20} height={20} />
          </div>
        </div>
      </InteractiveTooltip>
    );
  };

  const filteredIcons = location.pathname.startsWith('/interactives/')
    ? icons.filter((icon) => icon.className !== 'createIcon')
    : icons;
  return <div className='left-content'>{filteredIcons.map((icon) => renderIcon(icon))}</div>;
};

export default IconsSidebar;
