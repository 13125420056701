/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Form, Row } from 'react-bootstrap';
import { ToastManager } from '@crystaldelta/loree-ui-components';
import { deleteContent, toggleActive } from '../../middleware/api';
import CircleLoader from '../loader/loader';
import { getComponentLink, handleEmbedLINT, libraryTypes } from './utils';
import { getModalDetails, setSharedAccountId } from '../../middleware/utils';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel,
  FilterFn,
} from '@tanstack/react-table';
import { ReactComponent as SortUpDownIcon } from '../../assets/icons/sortingIcon.svg';
import { ReactComponent as SortUpIcon } from '../../assets/icons/sortUpIcon.svg';
import { ReactComponent as SortDownIcon } from '../../assets/icons/sortDownIcon.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/loree-edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/loree-trash.svg';
import { ReactComponent as DuplicateIcon } from '../../assets/icons/loree-duplicate.svg';
import './interactiveTable.scss';
import { rankItem } from '@tanstack/match-sorter-utils';
import Search from '../utils/typeSearch';
import ModalComponent from '../utils/modal';
import PaginationTanStack from './paginationTanstack';
import { getInteractiveTitle } from '../formComponent/utils';
import DropDownComponent from '../utils/dropDown';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as MenuIcon } from '../../assets/icons/loree-menu.svg';

export interface SelectedElementDetails {
  obscure_key?: string;
  active?: boolean;
  id?: string;
  user_id: string;
  title?: string;
  library_id?: number;
  library_type?: string;
  count?: number;
}

interface ConfigInterface {
  edit?: boolean;
  delete?: boolean;
  duplicate?: boolean;
  myinteractivecreate?: boolean;
  globalinteractivecreate?: boolean;
}

const ContentList = (props: any) => {
  const [dataCollection, setDataCollection] = useState([]);
  const [config, setConfig] = useState<ConfigInterface>({});
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnVisibility, setColumnVisibility] = useState({});
  const [fetching, setFetching] = useState(props.fetching ?? true);
  const [selectionDetails, setSelectionDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isSearchText, setIsSearchText] = useState(false);

  useEffect(() => {
    if (props.interactiveLists?.length > 0) {
      setSharedAccountId(props.location.search);
      const { edit, duplicate } = props.config;
      setColumnVisibility({
        count: true,
        title: true,
        libraryType: true,
        status: true,
        actions: edit || props.config.delete || duplicate,
      });
      setDataCollection(props.interactiveLists);
      setConfig(props.config);
    }
    setFetching(props.fetching);
  }, [props]);

  const handleStatusToggle = async (selectedElementDetails: SelectedElementDetails) => {
    const { id, user_id, active, library_id } = selectedElementDetails;
    const response = await toggleActive(user_id, !active, id, library_id);
    if (!response.message.includes('status updated')) return;
    const updatedListData = [...dataCollection];
    updatedListData.forEach((content: SelectedElementDetails) => {
      if (content.obscure_key === id) {
        content.active = !active;
      }
    });
    setDataCollection(updatedListData);
    setShowToast(true);
    setToastMessage(
      `This component will be ${!active ? 'available' : 'unavailable'} in the Loree-editor`,
    );
  };

  const handleDelete = (selectedElementDetails: SelectedElementDetails) => {
    setShowModal(true);
    setSelectionDetails(selectedElementDetails);
  };

  const deleteInteractive = async () => {
    setShowModal(false);
    const { id, user_id } = selectionDetails as SelectedElementDetails;
    const deletedReponse = await deleteContent(user_id, id);
    if (deletedReponse.message !== 'Content Deleted') return;
    let refreshedData = [...dataCollection];
    refreshedData = refreshedData.filter(function (el: SelectedElementDetails) {
      return el.obscure_key !== id;
    });
    refreshedData.forEach((data: SelectedElementDetails, index) => {
      data.count = index + 1;
    });
    setDataCollection(refreshedData);
    setShowToast(true);
    setToastMessage('Your content has been deleted.');
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row: any) => row.count, {
      id: 'count',
      header: () => <div className='title'>S.No</div>,
      enableGlobalFilter: false,
      enableSorting: false,
      cell: ({ row, table }) => {
        return (
          <div>
            {(table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) ||
              0) + 1}
          </div>
        );
      },
    }),
    columnHelper.accessor((row: any) => row.title, {
      id: 'title',
      size: '60%' as any,
      header: () => <div className='title'>Title</div>,
      cell: (info: any) => (
        <Link
          to={{
            pathname: `/contentview/${info.row.original.library_type}/${info.row.original.user_id}/${info.row.original.obscure_key}`,
            state: {
              user: sessionStorage.getItem('user'),
              user_id: sessionStorage.getItem('user_id'),
              config: config,
            },
          }}
          className='cursor-pointer'
        >
          {info.getValue()}
        </Link>
      ),
      enableSorting: true,
    }),
    columnHelper.accessor((row: any) => row.library_type, {
      id: 'library_type',
      size: '20%' as any,
      header: () => <div className='title'>Type</div>,
      cell: (info) => <div>{getInteractiveTitle(info.getValue())}</div>,
      enableGlobalFilter: false,
      enableSorting: true,
    }),
    columnHelper.accessor((row: any) => row.active, {
      id: 'status',
      size: '10%' as any,
      enableGlobalFilter: false,
      enableSorting: true,
      header: () => <div className='title'>Status</div>,
      cell: (info: any) => {
        return (
          <Form.Check
            type='switch'
            checked={info.getValue()}
            label={info.getValue() ? 'Active' : 'Inactive'}
            onChange={async () => await handleStatusToggle(info.row.original)}
          />
        );
      },
    }),
    columnHelper.accessor((row: any) => row.active, {
      id: 'embed',
      size: '10%' as any,
      enableGlobalFilter: false,
      enableSorting: false,
      header: () => <div className='title'>Embed</div>,
      cell: (info: any) => {
        const isActive = info.row.original.active;
        return (
          <button
            disabled={!isActive}
            className='btn btn-primary btn-sm embed-btn'
            onClick={() => handleEmbedLINT(info.row.original)}
          >
            Embed
          </button>
        );
      },
    }),
    columnHelper.accessor((row: any) => row, {
      size: '5%' as any,
      id: 'actions',
      enableGlobalFilter: false,
      header: () => <div className='text-center justify-content-center'>Actions</div>,
      cell: (info: any) => {
        return (
          <Dropdown className='tablepage-dropdown d-flex justify-content-center'>
            <Dropdown.Toggle
              id='dropdown-basic'
              className='tablepage-dropdown-toggle'
              variant='link'
            >
              <MenuIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {config.edit && (
                <Dropdown.Item>
                  <Link
                    className='text-center w-100'
                    to={{
                      pathname: getComponentLink(info.row.original, 'edit'),
                      state: {
                        user: sessionStorage.getItem('user'),
                        user_id: sessionStorage.getItem('user_id'),
                        config: config,
                        fontFamilyList: props.fontFamilyList,
                        customHeaderStyleList: props.customHeaderStyleList,
                      },
                    }}
                  >
                    <EditIcon />
                    <span>Edit</span>
                  </Link>
                </Dropdown.Item>
              )}
              {(config.myinteractivecreate ?? config.globalinteractivecreate) &&
                config.duplicate && (
                  <Dropdown.Item>
                    <Link
                      className='text-center w-100'
                      to={{
                        pathname: getComponentLink(info.row.original, 'duplicate'),
                        state: {
                          contentList: dataCollection,
                          config: config,
                          fontFamilyList: props.fontFamilyList,
                          customHeaderStyleList: props.customHeaderStyleList,
                        },
                      }}
                    >
                      <DuplicateIcon />
                      <span>Duplicate</span>
                    </Link>
                  </Dropdown.Item>
                )}
              {config.delete && (
                <Dropdown.Item>
                  <button
                    className='text-center w-100'
                    onClick={() => handleDelete(info.row.original)}
                  >
                    <DeleteIcon />
                    <span>Delete</span>
                  </button>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
      enableSorting: false,
    }),
  ];

  const fuzzyFilter = (row: any, columnId: any, value: string, addMeta: Function) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank,
    });
    const text = itemRank.rankedValue.toLowerCase();
    value = value.toLowerCase();
    return text.includes(value);
  };

  const table = useReactTable({
    data: dataCollection,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter as FilterFn<any>,
    },
    state: {
      columnVisibility,
      globalFilter,
    },
    defaultColumn: {
      minSize: 0,
      size: 20,
      maxSize: 20,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  const headerGroups = table.getHeaderGroups();
  const typeColumn = headerGroups[0].headers.find((header) => header.id === 'library_type');
  const typeColumnFilterValue = typeColumn?.column.getFilterValue();
  const [selectedType, setSelectedType] = useState(
    !typeColumnFilterValue ? '' : typeColumnFilterValue,
  );

  return (
    <>
      {fetching ? (
        <CircleLoader loaderType='tableViewLoader' />
      ) : (
        <Container fluid className='margin landingPageLoreeInteractive' id='interactiveContainer'>
          <Row>
            <div>
              <div className='interactive-list-wrapper py-3'>
                <div className='d-flex interactive-list-titlebar'>
                  <div className='d-inline-flex interactive-lists align-items-center'>
                    <h2 className='interactive-list-heading p-0 m-0'> List of Interactives</h2>
                  </div>
                  <div className='d-inline-flex flex-fill justify-content-end align-items-center'>
                    <Search
                      initialvalue={globalFilter ?? ''}
                      onChange={(value: string) => setGlobalFilter(String(value))}
                      isSearchText={isSearchText}
                      setIsSearchText={setIsSearchText}
                    />
                    <Form.Group
                      className='px-2 interactive-dropdown-container'
                      id='interactiveDropdown'
                    >
                      <DropDownComponent
                        setSelectedOption={(e: string) => {
                          typeColumn?.column.setFilterValue(e);
                          setSelectedType(e);
                        }}
                        selectedOption={selectedType as string}
                        className='lint-dropdown'
                        optionCollection={libraryTypes}
                        type='filter'
                      />
                      <div className='arrow-down' />
                    </Form.Group>
                  </div>
                </div>

                <div
                  className='table-container'
                  style={{
                    position: 'relative',
                    display: 'inline-block',
                    width: '100%',
                    marginTop: '3%',
                    marginBottom: '3%',
                  }}
                >
                  <table className='table-page lint-custom-tanStack-table' id='interactiveTable'>
                    <thead className='table-page-head' id='interactiveTableHead'>
                      {table.getHeaderGroups()?.map((headerGroup) => (
                        <tr key={headerGroup.id} className='tablepage-head-row'>
                          {headerGroup.headers.map((header) => (
                            <th
                              key={header.id}
                              style={{
                                width: header.column.columnDef.size ?? 'auto',
                                marginRight: '5px',
                              }}
                            >
                              {!header.column.getCanSort() &&
                                flexRender(header.column.columnDef.header, header.getContext())}
                              {header.column.getCanSort() && (
                                <button
                                  {...{
                                    className: 'sorting-wrapper select-none',
                                    onClick: header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {flexRender(header.column.columnDef.header, header.getContext())}
                                  {!header.column.getIsSorted() && (
                                    <div
                                      className='icon'
                                      style={{
                                        width: header.column.columnDef.size ?? 'auto',
                                      }}
                                    >
                                      <SortUpDownIcon />
                                    </div>
                                  )}
                                  {{
                                    asc: (
                                      <div className='icon'>
                                        <SortUpIcon />
                                      </div>
                                    ),
                                    desc: (
                                      <div className='icon'>
                                        <SortDownIcon />
                                      </div>
                                    ),
                                  }[header?.column?.getIsSorted() as string] ?? null}
                                </button>
                              )}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                  </table>
                  <div
                    className={
                      table.getRowModel().rows.length <= 3 ? 'row-table-wrapper' : 'table-wrapper'
                    }
                  >
                    <div
                      className={
                        table.getRowModel().rows.length <= 3
                          ? 'row-scroll-container'
                          : 'table-body-scroll-container'
                      }
                    >
                      <table className='table-page lint-custom-tanStack-table'>
                        <tbody className='table-page-body' id='interactiveTableBody'>
                          {table.getRowModel().rows?.length <= 0 && (
                            <tr>
                              <td colSpan={8} className='text-center'>
                                No Interactives Found
                              </td>
                            </tr>
                          )}
                          {table.getRowModel().rows?.length > 0 &&
                            table.getRowModel().rows.map((row) => (
                              <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                  <td
                                    key={cell.id}
                                    className='table-body-cell'
                                    style={{
                                      width: cell.column.columnDef.size ?? 'auto',
                                    }}
                                  >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </td>
                                ))}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className='loree-custom-tanStack-table-pagination p-2'>
                  <PaginationTanStack table={table} />
                </div>
              </div>

              <ModalComponent
                show={showModal}
                handleCancel={() => setShowModal(false)}
                modalDetails={getModalDetails(
                  'Delete',
                  (selectionDetails as SelectedElementDetails).title,
                )}
                confirmButton={{
                  handleConfirm: deleteInteractive,
                  label: 'Delete',
                  disabled: false,
                }}
                cancelButton={{
                  label: 'Cancel',
                }}
              />
            </div>
          </Row>
        </Container>
      )}
      {showToast && (
        <ToastManager
          toastType='success'
          toastMessage={toastMessage}
          closeButton
          closeToast={() => setShowToast(false)}
        />
      )}
    </>
  );
};

export default ContentList;
